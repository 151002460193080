import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";
export default defineComponent({
    name: "Under-Contruction",
    components: {},
    setup: function () {
        var _a;
        var store = useStore();
        var store_name = (_a = store.getters.getStoreData) === null || _a === void 0 ? void 0 : _a.name;
        onMounted(function () {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
        });
        onUnmounted(function () {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });
        return {
            getIllustrationsPath: getIllustrationsPath,
            store_name: store_name
        };
    },
});
