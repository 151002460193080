import axios from "axios";
// import { useStore } from "vuex";

export const getStore = async () => {
  // const fullDomain = window.location.hostname;
  const host = window.location.host;
  const subDomain = host.split(".")[0];

  // axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/v1/`;
  return await axios.get(
    `${process.env.VUE_APP_API_URL}/api/v1/shop-info/${
      subDomain === "192" || subDomain.indexOf("localhost") > -1
        ? "daratest"
        : subDomain
    }.${process.env.VUE_APP_BASE_DOMAIN}`
  );
};

export const getCartUuid = async (payload) => {
  return await axios.get(
    `${process.env.VUE_APP_API_URL}/api/v1/${payload}/get/token`
  );
};
