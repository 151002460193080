import { __awaiter, __generator } from "tslib";
import { getAddress } from "@/api/AddressApi";
import { applyCoupon, applyShippingMethod, checkout, shippingMethodList, } from "@/api/CartApi";
import useNotyf from "@/composable/useNotyf";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getCartList, translate } from "@/core/helpers/custom";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Multiselect from "@vueform/multiselect";
import { useI18n } from "vue-i18n/index";
import Toggle from "@vueform/toggle";
import { showOrder } from "@/api/OrderApi";
export default defineComponent({
    name: "Checkout",
    components: {
        ErrorMessage: ErrorMessage,
        Field: Field,
        Form: Form,
        Multiselect: Multiselect,
        Toggle: Toggle,
    },
    setup: function () {
        var _this = this;
        var _a;
        var shippingMethods = ref({});
        // const address = ref({} as IAddressDetails);
        var addresses = ref([]);
        var router = useRouter();
        var paymentMethod = ref({
            id: 1,
        });
        var currentUser = computed(function () {
            return store.getters.currentUser;
        });
        var notification = useNotyf();
        var loading = ref({
            coupon: false,
            checkout: false,
        });
        var addressId = ref();
        var cart = computed(function () {
            return store.getters.currentCart;
        });
        var code = ref("");
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var unAuthenticatedCheckout = ref({});
        var authenticatedCheckout = ref({});
        var center = ref({ lat: 11.5564, lng: 104.9282 });
        var marker = ref({
            position: center,
        });
        var contactInfoModal = ref({
            isShow: false,
        });
        var addressModal = ref({
            isShow: false,
        });
        var openAddressModal = function () {
            addressModal.value.isShow = true;
        };
        var closeAddressModal = function () {
            addressModal.value.isShow = false;
        };
        var openEditInfoModal = function () {
            contactInfoModal.value.isShow = true;
        };
        var closeContactInfoModal = function () {
            contactInfoModal.value.isShow = false;
        };
        // const addressModal = ref({
        //   isShow: false,
        // });
        //Create form validation object
        var checkoutAuthenticatedValidate = Yup.object().shape({
            // phone: Yup.string().required().label("Phone"),
            name: Yup.string().required("Required").label("Address Name"),
            phone: Yup.string().required("Required").label("Address"),
        });
        var checkoutUnauthenticatedValidate = Yup.object().shape({
            phone: Yup.string().required("Required").label("Phone"),
            fullname: Yup.string().required("Required").label("Full name"),
            address: Yup.string().required("Required").label("Address"),
            name: Yup.string().required("Required").label("Address"),
        });
        var getShippingMethod = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, shippingMethodList({})
                            .then(function (res) {
                            shippingMethods.value = res.data.data;
                            console.log(shippingMethods.value);
                            // console.log(Array.isArray(cart.value.shipping))
                            // console.log(cart.value.shipping)
                            if (!cart.value.shipping) {
                                if (shippingMethods.value.every(function (method) { return method.is_default === false; })) {
                                    applyShippingMethod({
                                        shipping_id: shippingMethods.value[shippingMethods.value.length - 1].id,
                                    }).then(function (res) {
                                        console.log("this is update");
                                        getCartList();
                                    });
                                }
                                else {
                                    shippingMethods.value.forEach(function (method) {
                                        if (method.is_default) {
                                            applyShippingMethod({
                                                shipping_id: method.id,
                                            }).then(function (res) {
                                                console.log("shipping method has been update");
                                                getCartList();
                                            });
                                        }
                                    });
                                }
                            }
                        })
                            .catch(function () {
                            "_";
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var getAddresses = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isUserAuthenticated.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, getAddress({ request_limit: "No" })
                                .then(function (res) {
                                var _a;
                                addresses.value = res.data.data;
                                for (var _i = 0, _b = addresses.value; _i < _b.length; _i++) {
                                    var address = _b[_i];
                                    if (address === null || address === void 0 ? void 0 : address.is_default) {
                                        addressId.value = address === null || address === void 0 ? void 0 : address.id;
                                    }
                                    var current_saved_id = (_a = router.currentRoute.value.query.current_save_id) !== null && _a !== void 0 ? _a : null;
                                    if (current_saved_id) {
                                        addressId.value = parseInt(String(current_saved_id));
                                    }
                                }
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var changeShippingMethod = function (shippingMethodId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, applyShippingMethod({
                            shipping_id: shippingMethodId,
                        }).then(function (res) {
                            notification.success(
                            // res.data.message ?? "Change Shipping Method Successfully."
                            t("Change Shipping Method Successfully."));
                        })];
                    case 1:
                        _a.sent();
                        getCartList();
                        return [2 /*return*/];
                }
            });
        }); };
        var applyCouponDiscount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.coupon = true;
                        return [4 /*yield*/, applyCoupon({
                                code: code.value,
                            })
                                .then(function (res) {
                                // notification.success(res.data.message ?? "Coupon add successfully.");
                                notification.success(translate("Coupon add successfully."));
                                getCartList();
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        code.value = "";
                        loading.value.coupon = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var checkOutAction = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.checkout = true;
                        if (!isUserAuthenticated.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, checkout({
                                shipping_address_id: addressId.value,
                                payment_method: "1",
                                telephone: authenticatedCheckout.value.phone,
                                fullname: authenticatedCheckout.value.name,
                            })
                                .then(function (res) {
                                // notification.success(res.data.message ?? "Checkout Successfully.");
                                notification.success(translate("Checkout Successfully."));
                                emailNotificationAlert(res);
                                redirectToOrderStaticPage(res);
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        unAuthenticatedCheckout.value.is_default = true;
                        return [4 /*yield*/, checkout(unAuthenticatedCheckout.value)
                                .then(function (res) {
                                // notification.success(res.data.message ?? "Checkout Successfully.");
                                notification.success(translate("Checkout Successfully."));
                                emailNotificationAlert(res);
                                redirectToOrderStaticPage(res);
                            })
                                .catch(function (e) {
                                console.log(e);
                                ("_");
                            })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        getCartList();
                        loading.value.checkout = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var addAddress = function () {
            if (isUserAuthenticated.value) {
                router.push({ name: "account-address-create" });
            }
            else {
                alert("xxx");
            }
        };
        var storeData = computed(function () {
            return store.getters.getStoreData;
        });
        // ----------------------------------------
        var route = useRoute();
        // const addressDetails = ref({} as IAddressDetails);
        var t = useI18n().t;
        var currentSavedId = ref(null);
        // const formLoading = ref(false);
        // const loading = ref({
        //   form: false,
        //   submit: false,
        // });
        var show = ref({
            map: false,
        });
        var googleMapKey = (_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.VUE_APP_GOOGEL_MAP_KEY;
        // const marker = ref({
        //   position: center,
        // });
        var centerModal = ref({
            lat: 11.578956353229527,
            lng: 104.88878130912781,
        });
        var markerModal = ref({
            position: centerModal,
        });
        //Create form validation object
        var addressValidate = Yup.object().shape({
            name: Yup.string().required("Required").label("Name"),
            // firstname: Yup.string().required().label("First Name"),
            // lastname: Yup.string().required().label("Last Name"),
            // mobile: Yup.string().required("Required").label("Mobile"),
            // email: Yup.string().email().label("Email"),
            address: Yup.string().required("Required").label("Address"),
        });
        var onSubmitAddress = function (_value, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    alert("submit");
                    return [2 /*return*/];
                });
            });
        };
        var moveMarker = function (event) {
            unAuthenticatedCheckout.value.lat = event.latLng.lat();
            unAuthenticatedCheckout.value.lng = event.latLng.lng();
            center.value = {
                lat: unAuthenticatedCheckout.value.lat,
                lng: unAuthenticatedCheckout.value.lng,
            };
            reverseAddress(center);
        };
        var moveMarkerModal = function (event) {
            centerModal.value.lat = event.latLng.lat();
            centerModal.value.lng = event.latLng.lng();
        };
        var setPlace = function (event) {
            markerModal.value.position = {
                lat: event.geometry.location.lat(),
                lng: event.geometry.location.lng(),
            };
            unAuthenticatedCheckout.value.lat = center.value.lat;
            unAuthenticatedCheckout.value.lng = center.value.lng;
        };
        function emailNotificationAlert(res) {
            if (res.data.is_email_sent) {
                notification.success(translate("Email has been sent!"));
            }
            else {
                notification.error(translate("Email has not been sent!"));
            }
        }
        function redirectToOrderStaticPage(res) {
            router.push({
                name: "static-ordered-page",
                query: {
                    uuid: res.data.uuid,
                },
            });
        }
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    marker.value.position = center.value = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    unAuthenticatedCheckout.value.lat = position.coords.latitude;
                    unAuthenticatedCheckout.value.lng = position.coords.longitude;
                    reverseAddress(centerModal);
                }, function (error) {
                    console.log("%cForm.vue line:335 error", "color: #007acc;", error);
                });
            }
            else {
                console.log("Geolocation is not supported by this browser.");
            }
        }
        var showMap = function () {
            show.value.map = !show.value.map;
        };
        var submitModal = function () {
            marker.value.position = center.value = {
                lat: centerModal.value.lat,
                lng: centerModal.value.lng,
            };
            unAuthenticatedCheckout.value.lat = centerModal.value.lat;
            unAuthenticatedCheckout.value.lng = centerModal.value.lng;
            reverseAddress(centerModal);
            closeMapModal();
        };
        var isMapShow = ref(false);
        function showMapModal() {
            isMapShow.value = true;
        }
        function closeMapModal() {
            isMapShow.value = false;
        }
        var reverseAddress = function (center) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: center.value }, function (res) {
                unAuthenticatedCheckout.value.address = res[0].formatted_address;
            });
        };
        var placeOrderScrollIfError = function () {
            setTimeout(function () {
                var firstError = document.getElementsByClassName("form-has-error");
                if (firstError.length > 0) {
                    // firstError[0].scrollTo({
                    //   top: 0,
                    //   behavior: "smooth",
                    // });
                    firstError[0].scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 250);
        };
        var getOrder = function (orderId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        var orderId = ref(route.params.orderId);
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, getCartList()];
                    case 1:
                        _c.sent();
                        // console.log(orderId.value)
                        if (store.getters.currentCart.countItem < 1) {
                            router.push({ name: "home" });
                            notification.error(translate("Cart is Empty!"));
                        }
                        // if (Array.isArray(cart.value?.shipping)) {
                        //   // choosing default method
                        //   console.log("this is working")
                        //   await changeShippingMethod(cart.value?.shipping.id);
                        // }
                        // if (cart.value?.shipping) {
                        //   // choosing default method
                        //   await applyShippingMethod({
                        //     shipping_id: cart.value?.shipping.id,
                        //   }).then((res) => {});
                        //   getCartList();
                        // }else{
                        //   // await applyShippingMethod({
                        //   //   shipping_id: 1,
                        //   // }).then((res) => {});
                        //   // getCartList();
                        // }
                        nextTick(function () {
                            store.commit(Mutations.SET_TOGGLE_SIDEBAR, false);
                            setCurrentPageBreadcrumbs("Checkout", ["Checkout"]);
                            getShippingMethod();
                            getAddresses();
                        });
                        if (!isUserAuthenticated.value) return [3 /*break*/, 5];
                        if (!orderId.value) return [3 /*break*/, 3];
                        // console.log(orderId.value)
                        // getOrder(orderId.value)
                        return [4 /*yield*/, showOrder(orderId.value)
                                .then(function (res) {
                                var reorder = res.data.data;
                                authenticatedCheckout.value.name = reorder.firstname + ' ' + reorder.lastname;
                                authenticatedCheckout.value.phone = reorder.telephone;
                                // console.log(reorder)
                                // console.log(addressModal)
                                getAddress({ request_limit: "No" })
                                    .then(function (res) {
                                    addresses.value = res.data.data;
                                    for (var _i = 0, _a = addresses.value; _i < _a.length; _i++) {
                                        var address = _a[_i];
                                        if (address.address === reorder.payment_address_1) {
                                            addressId.value = address === null || address === void 0 ? void 0 : address.id;
                                        }
                                    }
                                })
                                    .catch(function () {
                                    "_";
                                });
                                // for (const address of addresses.value) {
                                //   if (address.address === reorder.payment_address_1) {     
                                //     addressId.value = address?.id;
                                //     // console.log("this was update"+ addressId.value);
                                //   }
                                //   let current_saved_id =
                                //   router.currentRoute.value.query.current_save_id ?? null;
                                //   if (current_saved_id) {
                                //     addressId.value = parseInt(String(current_saved_id));
                                //   }
                                // }
                            })
                                .then(function () {
                                "_";
                            })];
                    case 2:
                        // console.log(orderId.value)
                        // getOrder(orderId.value)
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        authenticatedCheckout.value.name = (_a = currentUser.value) === null || _a === void 0 ? void 0 : _a.fullname;
                        authenticatedCheckout.value.phone = (_b = currentUser.value) === null || _b === void 0 ? void 0 : _b.phone;
                        _c.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        getLocation();
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); });
        return {
            cart: cart,
            router: router,
            shippingMethods: shippingMethods,
            addresses: addresses,
            changeShippingMethod: changeShippingMethod,
            applyCouponDiscount: applyCouponDiscount,
            loading: loading,
            addressId: addressId,
            checkOutAction: checkOutAction,
            code: code,
            isUserAuthenticated: isUserAuthenticated,
            checkoutAuthenticatedValidate: checkoutAuthenticatedValidate,
            unAuthenticatedCheckout: unAuthenticatedCheckout,
            center: center,
            marker: marker,
            paymentMethod: paymentMethod,
            addAddress: addAddress,
            contactInfoModal: contactInfoModal,
            storeData: storeData,
            addressModal: addressModal,
            authenticatedCheckout: authenticatedCheckout,
            currentUser: currentUser,
            closeContactInfoModal: closeContactInfoModal,
            addressValidate: addressValidate,
            showMapModal: showMapModal,
            closeMapModal: closeMapModal,
            centerModal: centerModal,
            isMapShow: isMapShow,
            onSubmitAddress: onSubmitAddress,
            route: route,
            googleMapKey: googleMapKey,
            moveMarker: moveMarker,
            setPlace: setPlace,
            show: show,
            showMap: showMap,
            moveMarkerModal: moveMarkerModal,
            submitModal: submitModal,
            markerModal: markerModal,
            currentSavedId: currentSavedId,
            checkoutUnauthenticatedValidate: checkoutUnauthenticatedValidate,
            openEditInfoModal: openEditInfoModal,
            openAddressModal: openAddressModal,
            closeAddressModal: closeAddressModal,
            placeOrderScrollIfError: placeOrderScrollIfError,
            getOrder: getOrder,
        };
    },
});
