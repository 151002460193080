import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-fd463c60"), n = n(), _popScopeId(), n); };
var _hoisted_1 = {
    style: { "flex": "0 0 375px" },
    class: "bg-white vegetable_sidebar h-100"
};
var _hoisted_2 = { class: "your_order" };
var _hoisted_3 = {
    class: "fw_700 fs_16 lh_24",
    style: { "color": "#182135" }
};
var _hoisted_4 = {
    role: "button",
    class: "text-gray-800 fw_700 fs_16 kt_sidebar_toggler d-none d-md-block d-lg-none"
};
var _hoisted_5 = {
    class: "scroll_div",
    style: { "height": "calc(100vh - 32.5rem)" }
};
var _hoisted_6 = {
    class: "fw_400 fs_16 lh_22 ms-3",
    style: { "letter-spacing": "-0.3px", "color": "#000000" }
};
var _hoisted_7 = {
    class: "px-1 d-flex justify-content-center align-items-center fw_400 fs_16 lh_22",
    style: { "min-width": "24.47px", "letter-spacing": "-0.3px", "color": "#000000" }
};
var _hoisted_8 = {
    class: "p-5 pt-0 mb-0 bg-white position-fixed",
    style: { "width": "375px", "bottom": "3.5rem", "box-shadow": "-6px 8px 8px 0px rgb(0 0 0 / 4%)" }
};
var _hoisted_9 = { class: "fw_600 fs_16 text-center lh_16 text-dark" };
var _hoisted_10 = { class: "fw_600 fs_16 lh_16 text-center text-dark" };
var _hoisted_11 = { class: "fw_600 fs_16 text-center lh_16 text-dark" };
var _hoisted_12 = { class: "fw_600 fs_16 lh_16 text-center text-dark" };
var _hoisted_13 = { class: "fw_600 fs_16 text-center lh_16 text-dark" };
var _hoisted_14 = { class: "fw_600 fs_16 lh_16 text-center text-dark" };
var _hoisted_15 = { class: "fw_600 fs_16 text-center lh_16 text-dark" };
var _hoisted_16 = { class: "fw_600 fs_16 lh_16 text-center text-dark" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_icon = _resolveComponent("base-icon");
    var _component_base_flex = _resolveComponent("base-flex");
    var _component_base_separator = _resolveComponent("base-separator");
    var _component_base_button = _resolveComponent("base-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_base_flex, {
            class: "flex-column sidebar-body ps-5",
            id: "kt_sidebar_body"
        }, {
            default: _withCtx(function () { return [
                _createVNode(_component_base_flex, { class: "flex-column" }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_base_flex, { class: "flex-column" }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_base_flex, { class: "justify-content-between align-items-center card p-3 pt-0 flex-row" }, {
                                    default: _withCtx(function () {
                                        var _a, _b, _c;
                                        return [
                                            _createElementVNode("div", null, [
                                                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Your Order")), 1),
                                                _createElementVNode("p", _hoisted_3, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("Menu Count")) + ": ", 1),
                                                    _createElementVNode("span", null, _toDisplayString(((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.products) ? (_c = (_b = _ctx.cart) === null || _b === void 0 ? void 0 : _b.products) === null || _c === void 0 ? void 0 : _c.length : 0), 1)
                                                ]),
                                                _createElementVNode("span", _hoisted_4, " ❮ " + _toDisplayString(_ctx.$t("Back")), 1)
                                            ]),
                                            _createVNode(_component_base_icon, {
                                                iconName: "bi bi-trash-fill",
                                                isGreenBackground: "",
                                                showLoading: _ctx.loading.clearAll,
                                                text: "Clear",
                                                onClick: _ctx.clearAll
                                            }, null, 8, ["showLoading", "onClick"])
                                        ];
                                    }),
                                    _: 1
                                }),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_TransitionGroup, {
                                        name: "fade",
                                        tag: "div"
                                    }, {
                                        default: _withCtx(function () {
                                            var _a;
                                            return [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.products, function (product, index) {
                                                    return (_openBlock(), _createBlock(_component_base_flex, {
                                                        class: "align-items-center card my-1 p-3 flex-row justify-content-between",
                                                        key: product === null || product === void 0 ? void 0 : product.cart_id
                                                    }, {
                                                        default: _withCtx(function () { return [
                                                            _createVNode(_component_base_flex, {
                                                                class: "fg_3",
                                                                style: { "max-width": "330px" }
                                                            }, {
                                                                default: _withCtx(function () { return [
                                                                    _createVNode(_component_base_icon, {
                                                                        iconName: "bi bi-trash-fill",
                                                                        noBorder: "",
                                                                        isGreenColor: "",
                                                                        zeroHW: true,
                                                                        onClick: function ($event) { return (_ctx.removeCartItem(product.cart_id, index + 1)); },
                                                                        showLoading: _ctx.loading.clearOneByOne === index + 1
                                                                    }, null, 8, ["onClick", "showLoading"]),
                                                                    _createElementVNode("span", _hoisted_6, _toDisplayString(product.name), 1)
                                                                ]; }),
                                                                _: 2
                                                            }, 1024),
                                                            _createVNode(_component_base_flex, { class: "flex-column" }, {
                                                                default: _withCtx(function () { return [
                                                                    _createVNode(_component_base_flex, {
                                                                        class: "justify-content-center align-self-end fw_400 lh_22 fs_16 pe_8",
                                                                        style: { "letter-spacing": "-0.3px", "color": "#000000" }
                                                                    }, {
                                                                        default: _withCtx(function () { return [
                                                                            _createElementVNode("span", null, "$ " + _toDisplayString(Number(product === null || product === void 0 ? void 0 : product.total).toFixed(2)), 1)
                                                                        ]; }),
                                                                        _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_base_flex, null, {
                                                                        default: _withCtx(function () { return [
                                                                            _createVNode(_component_base_flex, {
                                                                                class: "text-center",
                                                                                style: { "max-width": "112.08px" }
                                                                            }, {
                                                                                default: _withCtx(function () { return [
                                                                                    _createVNode(_component_base_icon, {
                                                                                        iconName: "bi bi-dash",
                                                                                        noBorder: "",
                                                                                        zeroHW: true,
                                                                                        class: "ps-0",
                                                                                        isGreenColor: "",
                                                                                        onClick: function ($event) { return (_ctx.adjustQty(product === null || product === void 0 ? void 0 : product.quantity, product === null || product === void 0 ? void 0 : product.cart_id, 'minus', index)); }
                                                                                    }, null, 8, ["onClick"]),
                                                                                    _createElementVNode("span", _hoisted_7, _toDisplayString(product.quantity), 1),
                                                                                    _createVNode(_component_base_icon, {
                                                                                        iconName: "bi bi-plus",
                                                                                        noBorder: "",
                                                                                        isGreenColor: "",
                                                                                        zeroHW: true,
                                                                                        class: "px-1",
                                                                                        onClick: function ($event) { return (_ctx.adjustQty(product === null || product === void 0 ? void 0 : product.quantity, product === null || product === void 0 ? void 0 : product.cart_id, 'plus', index)); }
                                                                                    }, null, 8, ["onClick"])
                                                                                ]; }),
                                                                                _: 2
                                                                            }, 1024)
                                                                        ]; }),
                                                                        _: 2
                                                                    }, 1024)
                                                                ]; }),
                                                                _: 2
                                                            }, 1024)
                                                        ]; }),
                                                        _: 2
                                                    }, 1024));
                                                }), 128))
                                            ];
                                        }),
                                        _: 1
                                    })
                                ])
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                })
            ]; }),
            _: 1
        }),
        _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_base_separator),
            _createVNode(_component_base_flex, {
                class: "justify-content-between",
                style: { "margin-top": "32px" }
            }, {
                default: _withCtx(function () {
                    var _a;
                    return [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Subtotal")), 1),
                        _createElementVNode("span", _hoisted_10, " $" + _toDisplayString(Number((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.sub_total).toFixed(2)), 1)
                    ];
                }),
                _: 1
            }),
            _createVNode(_component_base_flex, { class: "justify-content-between mt-3" }, {
                default: _withCtx(function () {
                    var _a;
                    return [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("Discount")), 1),
                        _createElementVNode("span", _hoisted_12, " $" + _toDisplayString(Number((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.discount).toFixed(2)), 1)
                    ];
                }),
                _: 1
            }),
            _createVNode(_component_base_flex, { class: "justify-content-between mt-3" }, {
                default: _withCtx(function () {
                    var _a;
                    return [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("Total")), 1),
                        _createElementVNode("span", _hoisted_14, " $" + _toDisplayString(Number((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.total).toFixed(2)), 1)
                    ];
                }),
                _: 1
            }),
            _createVNode(_component_base_flex, { class: "justify-content-between checkout_btn_width mt-3" }, {
                default: _withCtx(function () {
                    var _a;
                    return [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Item count")) + ": ", 1),
                        _createElementVNode("span", _hoisted_16, _toDisplayString((_a = _ctx.cart) === null || _a === void 0 ? void 0 : _a.countItem), 1)
                    ];
                }),
                _: 1
            }),
            _createVNode(_component_base_button, {
                class: "w-100 fw_700",
                type: "button",
                onClick: _ctx.gotoCheckout,
                style: { "margin-top": "48px" },
                isGreenBackground: ""
            }, {
                default: _withCtx(function () { return [
                    _createTextVNode(_toDisplayString(_ctx.$t("Checkout")), 1)
                ]; }),
                _: 1
            }, 8, ["onClick"])
        ])
    ]));
}
